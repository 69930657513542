const iconMap = {
    SubtractIcon: '<img class="umami-icon" src="/assets/umami-icons/SubtractIcon.svg">',
    UnlockIcon: '<img class="umami-icon" src="/assets/umami-icons/UnlockIcon.svg">',
    TakeawayIcon: '<img class="umami-icon" src="/assets/umami-icons/TakeawayIcon.svg">',
    WiFiIcon: '<img class="umami-icon" src="/assets/umami-icons/WiFiIcon.svg">',
    DollarSignIcon: '<img class="umami-icon" src="/assets/umami-icons/DollarSignIcon.svg">',
    MediumTipsIcon: '<img class="umami-icon" src="/assets/umami-icons/MediumTipsIcon.svg">',
    ReceiptIcon: '<img class="umami-icon" src="/assets/umami-icons/ReceiptIcon.svg">',
    PlaceholdericonIcon: '<img class="umami-icon" src="/assets/umami-icons/PlaceholdericonIcon.svg">',
    GraphbarsIcon: '<img class="umami-icon" src="/assets/umami-icons/GraphbarsIcon.svg">',
    GraphLineIcon: '<img class="umami-icon" src="/assets/umami-icons/GraphLineIcon.svg">',
    DeliverypointIcon: '<img class="umami-icon" src="/assets/umami-icons/DeliverypointIcon.svg">',
    RoomServiceIcon: '<img class="umami-icon" src="/assets/umami-icons/RoomServiceIcon.svg">',
    WebpageIcon: '<img class="umami-icon" src="/assets/umami-icons/WebpageIcon.svg">',
    BagEmptyIcon: '<img class="umami-icon" src="/assets/umami-icons/BagEmptyIcon.svg">',
    UseraccesslevelsIcon: '<img class="umami-icon" src="/assets/umami-icons/UseraccesslevelsIcon.svg">',
    ClockIcon: '<img class="umami-icon" src="/assets/umami-icons/ClockIcon.svg">',
    HelpsquareIcon: '<img class="umami-icon" src="/assets/umami-icons/HelpsquareIcon.svg">',
    ZoomOutIcon: '<img class="umami-icon" src="/assets/umami-icons/ZoomOutIcon.svg">',
    WithdrawIcon: '<img class="umami-icon" src="/assets/umami-icons/WithdrawIcon.svg">',
    DiscountIcon: '<img class="umami-icon" src="/assets/umami-icons/DiscountIcon.svg">',
    UnibillIcon: '<img class="umami-icon" src="/assets/umami-icons/UnibillIcon.svg">',
    IntegrationsIcon: '<img class="umami-icon" src="/assets/umami-icons/IntegrationsIcon.svg">',
    SentimentGoodIcon: '<img class="umami-icon" src="/assets/umami-icons/SentimentGoodIcon.svg">',
    EntreeIcon: '<img class="umami-icon" src="/assets/umami-icons/EntreeIcon.svg">',
    PropertyDrinksIcon: '<img class="umami-icon" src="/assets/umami-icons/PropertyDrinksIcon.svg">',
    TableServiceIcon: '<img class="umami-icon" src="/assets/umami-icons/TableServiceIcon.svg">',
    EditIcon: '<img class="umami-icon" src="/assets/umami-icons/EditIcon.svg">',
    InviteguestIcon: '<img class="umami-icon" src="/assets/umami-icons/InviteguestIcon.svg">',
    ArrowDiagonalUpRightIcon: '<img class="umami-icon" src="/assets/umami-icons/ArrowDiagonalUpRightIcon.svg">',
    SearchIcon: '<img class="umami-icon" src="/assets/umami-icons/SearchIcon.svg">',
    GlassBrokenIcon: '<img class="umami-icon" src="/assets/umami-icons/GlassBrokenIcon.svg">',
    ScoreIcon: '<img class="umami-icon" src="/assets/umami-icons/ScoreIcon.svg">',
    RefreshIcon: '<img class="umami-icon" src="/assets/umami-icons/RefreshIcon.svg">',
    CloseIcon: '<img class="umami-icon" src="/assets/umami-icons/CloseIcon.svg">',
    ChevronRightIcon: '<img class="umami-icon" src="/assets/umami-icons/ChevronRightIcon.svg">',
    HomeDeliveryIcon: '<img class="umami-icon" src="/assets/umami-icons/HomeDeliveryIcon.svg">',
    ArrowDownIcon: '<img class="umami-icon" src="/assets/umami-icons/ArrowDownIcon.svg">',
    GraphascendIcon: '<img class="umami-icon" src="/assets/umami-icons/GraphascendIcon.svg">',
    Icon: '<img class="umami-icon" src="/assets/umami-icons/Icon.svg">',
    StarFilledIcon: '<img class="umami-icon" src="/assets/umami-icons/StarFilledIcon.svg">',
    NonalcoholicIcon: '<img class="umami-icon" src="/assets/umami-icons/NonalcoholicIcon.svg">',
    SentimentVeryUnhappyIcon: '<img class="umami-icon" src="/assets/umami-icons/SentimentVeryUnhappyIcon.svg">',
    ChevronDownIcon: '<img class="umami-icon" src="/assets/umami-icons/ChevronDownIcon.svg">',
    PhoneIcon: '<img class="umami-icon" src="/assets/umami-icons/PhoneIcon.svg">',
    ArrowRightIcon: '<img class="umami-icon" src="/assets/umami-icons/ArrowRightIcon.svg">',
    ChatIcon: '<img class="umami-icon" src="/assets/umami-icons/ChatIcon.svg">',
    GooglePayIcon: '<img class="umami-icon" src="/assets/umami-icons/GooglePayIcon.svg">',
    MenuIcon: '<img class="umami-icon" src="/assets/umami-icons/MenuIcon.svg">',
    ChevronLeftIcon: '<img class="umami-icon" src="/assets/umami-icons/ChevronLeftIcon.svg">',
    GooglelogoIcon: '<img class="umami-icon" src="/assets/umami-icons/GooglelogoIcon.svg">',
    HomeIcon: '<img class="umami-icon" src="/assets/umami-icons/HomeIcon.svg">',
    ArrowLeftIcon: '<img class="umami-icon" src="/assets/umami-icons/ArrowLeftIcon.svg">',
    ApplelogoIcon: '<img class="umami-icon" src="/assets/umami-icons/ApplelogoIcon.svg">',
    CashIcon: '<img class="umami-icon" src="/assets/umami-icons/CashIcon.svg">',
    LoginIcon: '<img class="umami-icon" src="/assets/umami-icons/LoginIcon.svg">',
    SeatIcon: '<img class="umami-icon" src="/assets/umami-icons/SeatIcon.svg">',
    WarningIcon: '<img class="umami-icon" src="/assets/umami-icons/WarningIcon.svg">',
    PropertySnacksIcon: '<img class="umami-icon" src="/assets/umami-icons/PropertySnacksIcon.svg">',
    CartIcon: '<img class="umami-icon" src="/assets/umami-icons/CartIcon.svg">',
    EmailIcon: '<img class="umami-icon" src="/assets/umami-icons/EmailIcon.svg">',
    MapPinIcon: '<img class="umami-icon" src="/assets/umami-icons/MapPinIcon.svg">',
    PowerPlugIcon: '<img class="umami-icon" src="/assets/umami-icons/PowerPlugIcon.svg">',
    LogoutIcon: '<img class="umami-icon" src="/assets/umami-icons/LogoutIcon.svg">',
    HamburgerIcon: '<img class="umami-icon" src="/assets/umami-icons/HamburgerIcon.svg">',
    LocationIcon: '<img class="umami-icon" src="/assets/umami-icons/LocationIcon.svg">',
    VippsIcon: '<img class="umami-icon" src="/assets/umami-icons/VippsIcon.svg">',
    DessertIcon: '<img class="umami-icon" src="/assets/umami-icons/DessertIcon.svg">',
    PropertyEntreIcon: '<img class="umami-icon" src="/assets/umami-icons/PropertyEntreIcon.svg">',
    VisaIcon: '<img class="umami-icon" src="/assets/umami-icons/VisaIcon.svg">',
    ZoomInIcon: '<img class="umami-icon" src="/assets/umami-icons/ZoomInIcon.svg">',
    AlcoholIcon: '<img class="umami-icon" src="/assets/umami-icons/AlcoholIcon.svg">',
    InvoiceIcon: '<img class="umami-icon" src="/assets/umami-icons/InvoiceIcon.svg">',
    LockIcon: '<img class="umami-icon" src="/assets/umami-icons/LockIcon.svg">',
    USBIcon: '<img class="umami-icon" src="/assets/umami-icons/USBIcon.svg">',
    SentimentNeutralIcon: '<img class="umami-icon" src="/assets/umami-icons/SentimentNeutralIcon.svg">',
    ConferenceRoomIcon: '<img class="umami-icon" src="/assets/umami-icons/ConferenceRoomIcon.svg">',
    MessageIcon: '<img class="umami-icon" src="/assets/umami-icons/MessageIcon.svg">',
    MastercardIcon: '<img class="umami-icon" src="/assets/umami-icons/MastercardIcon.svg">',
    BluetoothIcon: '<img class="umami-icon" src="/assets/umami-icons/BluetoothIcon.svg">',
    ExternallinkIcon: '<img class="umami-icon" src="/assets/umami-icons/ExternallinkIcon.svg">',
    SendIcon: '<img class="umami-icon" src="/assets/umami-icons/SendIcon.svg">',
    SettingsIcon: '<img class="umami-icon" src="/assets/umami-icons/SettingsIcon.svg">',
    DepositIcon: '<img class="umami-icon" src="/assets/umami-icons/DepositIcon.svg">',
    CalendarIcon: '<img class="umami-icon" src="/assets/umami-icons/CalendarIcon.svg">',
    CreditCardhandIcon: '<img class="umami-icon" src="/assets/umami-icons/CreditCardhandIcon.svg">',
    GratitudeIcon: '<img class="umami-icon" src="/assets/umami-icons/GratitudeIcon.svg">',
    InfoIcon: '<img class="umami-icon" src="/assets/umami-icons/InfoIcon.svg">',
    OrderlogIcon: '<img class="umami-icon" src="/assets/umami-icons/OrderlogIcon.svg">',
    OverflowIcon: '<img class="umami-icon" src="/assets/umami-icons/OverflowIcon.svg">',
    HighTipsIcon: '<img class="umami-icon" src="/assets/umami-icons/HighTipsIcon.svg">',
    BagFilledIcon: '<img class="umami-icon" src="/assets/umami-icons/BagFilledIcon.svg">',
    FeedbackIcon: '<img class="umami-icon" src="/assets/umami-icons/FeedbackIcon.svg">',
    AddIcon: '<img class="umami-icon" src="/assets/umami-icons/AddIcon.svg">',
    SentimentVeryGoodIcon: '<img class="umami-icon" src="/assets/umami-icons/SentimentVeryGoodIcon.svg">',
    SpinnerIcon: '<img class="umami-icon" src="/assets/umami-icons/SpinnerIcon.svg">',
    PiechartIcon: '<img class="umami-icon" src="/assets/umami-icons/PiechartIcon.svg">',
    ApplePayIcon: '<img class="umami-icon" src="/assets/umami-icons/ApplePayIcon.svg">',
    SnackIcon: '<img class="umami-icon" src="/assets/umami-icons/SnackIcon.svg">',
    UserIcon: '<img class="umami-icon" src="/assets/umami-icons/UserIcon.svg">',
    RefundIcon: '<img class="umami-icon" src="/assets/umami-icons/RefundIcon.svg">',
    CheckmarkIcon: '<img class="umami-icon" src="/assets/umami-icons/CheckmarkIcon.svg">',
    SentimentUnhappyIcon: '<img class="umami-icon" src="/assets/umami-icons/SentimentUnhappyIcon.svg">',
    PickupIcon: '<img class="umami-icon" src="/assets/umami-icons/PickupIcon.svg">',
    LowTipsIcon: '<img class="umami-icon" src="/assets/umami-icons/LowTipsIcon.svg">',
    TrashIcon: '<img class="umami-icon" src="/assets/umami-icons/TrashIcon.svg">',
    StarEmptyIcon: '<img class="umami-icon" src="/assets/umami-icons/StarEmptyIcon.svg">',
    PropertyRestaurantIcon: '<img class="umami-icon" src="/assets/umami-icons/PropertyRestaurantIcon.svg">',
    ChevronUpIcon: '<img class="umami-icon" src="/assets/umami-icons/ChevronUpIcon.svg">',
    CreditCardIcon: '<img class="umami-icon" src="/assets/umami-icons/CreditCardIcon.svg">',
    ArrowUpIcon: '<img class="umami-icon" src="/assets/umami-icons/ArrowUpIcon.svg">',
    GiftBoxIcon: '<img class="umami-icon" src="/assets/umami-icons/GiftBoxIcon.svg">',
    LeftPanelIcon: '<img class="umami-icon" src="/assets/umami-icons/LeftPanelIcon.svg">',
    AiSpark: '<img class="umami-icon" src="/assets/umami-icons/AiSpark.svg">',
    TableMapIcon: '<img class="umami-icon" src="/assets/umami-icons/TableMapIcon.svg">'
};

type Icons = keyof typeof iconMap;
type iconValue = { icon: string; pack: string };
export const icons: Record<Icons, iconValue> = Object.keys(iconMap).reduce(
    (obj, key) => ({ [key]: { icon: key, pack: 'umami' }, ...obj }),
    {}
) as Record<Icons, iconValue>;

export default iconMap;
