import { OnBoardingService } from 'app/shared/services/on-boarding.service';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';

@Component({
    selector: 'get-started-contact-us-form-container-public',
    templateUrl: './get-started-contact-us-form-container-public.component.html',
    styleUrls: ['./get-started-contact-us-form-container-public.component.scss']
})
export class GetStartedContactUsFormContainerPublicComponent {
    submitErrorResponse = new Subject();

    constructor(
        private onboardingService: OnBoardingService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    handleFormSubmit(form) {
        this.onboardingService.contactUs(form).subscribe(
            res => {
                this.router.navigate(['contact-us-confirmation'], { relativeTo: this.route.parent });
            },
            err => {
                this.submitErrorResponse.next(err);
            }
        );
    }
}
