import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParameterCodec } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { QuickerLoginEnabledDto } from "../../interfaces/feature-toggle/feature-toggle-types";
import { SuggestedPinCodeResponse } from '../../interfaces/quickswitch/SuggestedPinCodeResponse';

@Injectable({
    providedIn: 'root'
})
export class QuickSwitchControllerService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
        }

        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }

    }

    public getSuggestedPinCode(locationId: number, username?: string): Observable<SuggestedPinCodeResponse> {
        const url = `${this.configuration.basePath}/v2/locations/${locationId}/pincodes/suggest`;
        const options = {
            params: username ? {username} : {}
        };
        return this.httpClient.get<SuggestedPinCodeResponse>(url, options);
    }

    public setFeatureEnabled(locationId: number, enabled: boolean): Observable<QuickerLoginEnabledDto> {
        const url = `${this.configuration.basePath}/v1/locations/${locationId}/features/quicker-login/${enabled}`;

        return this.httpClient.post<QuickerLoginEnabledDto>(url, undefined);
    }

    public isFeatureEnabled(locationId: number): Observable<QuickerLoginEnabledDto> {
        const url = `${this.configuration.basePath}/v1/locations/${locationId}/features/quicker-login`;

        return this.httpClient.get<QuickerLoginEnabledDto>(url);
    }
}
