import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { Subject } from 'rxjs-compat';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'nb-playground-oauth2-callback',
    template: ` <p>Authenticating...</p> `
})
export class Callback implements OnDestroy {
    private destroy$ = new Subject<void>();

    constructor(
        private authService: NbAuthService,
        private router: Router
    ) {
        this.authService
            .authenticate('sso')
            .pipe(takeUntil(this.destroy$))
            .subscribe(async (authResult: NbAuthResult) => {
                if (authResult.isSuccess()) {
                    await this.authService.refreshToken('sso', authResult.getToken()).toPromise();
                    const redirect = authResult.getRedirect() || 'location/locations';
                    this.router.navigateByUrl(redirect);
                    return;
                }

                this.router.navigateByUrl('/auth/login');
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
